import React from "react";
import gymvideo from "../assets/gymvideo.mp4";

export default function Filler() {
  return (
    <div className="filler-container" id="info">
      <h1>If there is no struggle, there is no progress</h1>
      <p>
        Today. Right now you are going to war. You are going to war with
        yourself. You are not scared… You are prepared You are not weak… You are
        a machine.
      </p>
      <video autoPlay loop muted>
        <source src={gymvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}
